<template>
    <header>
        <!-- Your gallery content here -->
    </header>
</template>

<script>
export default {
    name: 'AppGallery',
}
</script>

<style scoped>
/* Component-specific styles here */
</style>
