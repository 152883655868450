<template>
  <section id="services" class="services">
    <div class="card services-card">
      <div class="card-title px-3 pt-3">
        <h2 class="section-header">Our Services</h2>
        <div class="row row-cols-1 row-cols-md-2">
          <div class="col py-2">
            <div class="card h-100">
              <div class="card-body">
                <h3><i class="fas fa-code card-icon text-black"> Website Development</i></h3>
                <p class="card-text">Looking for a professional and functional website? Our expert web development
                  services ensure your website is tailored to your business needs. From concept to deployment, we'll
                  collaborate closely with you to create a stunning website that captures your brand identity, engages
                  your audience, and drives results. With a focus on user experience and modern design practices, we'll
                  bring your ideas to life and make your online presence stand out.</p>
              </div>
            </div>
          </div>
          <div class="col py-2">
            <div class="card h-100">
              <div class="card-body">
                <h3><i class="fas fa-search card-icon text-black"> UX Audits</i></h3>
                <p class="card-text">Is your website delivering the best user experience? Our UX audits can help you
                  identify areas of improvement and optimize your website for better engagement and conversions. We'll
                  conduct a comprehensive analysis of your website's usability, navigation, content structure, and overall
                  user experience, providing you with actionable recommendations to enhance user satisfaction.</p>
              </div>
            </div>
          </div>
          <div class="col py-2">
            <div class="card h-100">
              <div class="card-body">
                <h3><i class="fas fa-pencil-ruler card-icon text-black"> Website Redesign</i></h3>
                <p class="card-text">If your current website is outdated or not meeting your business goals, our
                  website
                  redesign services can give it a fresh and modern look. We'll work closely with you to understand your
                  vision and revamp your website with improved aesthetics, intuitive navigation, and responsive design.
                  Our goal is to create a visually appealing website that engages your audience and drives results.</p>
              </div>
            </div>
          </div>
          <div class="col py-2">
            <div class="card h-100">
              <div class="card-body">
                <h3><i class="fas fa-cogs card-icon"> Website Maintenance and Support</i></h3>
                <p class="card-text">Keeping your website up to date and running smoothly is crucial. Our website
                  maintenance and support services provide you with peace of mind, knowing that your website is in expert
                  hands. We'll handle regular updates, security checks, bug fixes, and provide technical support to ensure
                  your website remains secure, optimized, and delivers a seamless user experience.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
    name: 'AppService',
}
</script>

<style scoped>
/* Component-specific styles here */
</style>
