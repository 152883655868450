<template>
  <section id="about">
    <div class="card">
      <div class="card-title px-3 pt-3">
        <h2>About Us</h2>
      </div>
      <div class="card-body">
        <div class="centered-images">
          <div class="image-container">
            <img src="../assets/Me-min.jpg" alt="Me" class="side-by-side-image me-image">
          </div>
          <div class="image-container">
            <img src="../assets/Truck-min.jpg" alt="Truck" class="side-by-side-image truck-image">
          </div>
        </div>
        <p class="card-text">
          Welcome to Mobile Web Wizard! We are a web development and UX audit agency with a unique approach. Our mission is to provide top-notch services while on the road, allowing us to be flexible and adaptable to our clients' needs. Whether it's meeting up with clients in person or working remotely for maintenance and support, we're committed to delivering exceptional results.
        </p>
        <p class="card-text">
          At Mobile Web Wizard, we understand the importance of a strong online presence. That's why we specialize in web development, creating professional and functional websites that are tailored to your business needs. From concept to deployment, we collaborate closely with you to bring your ideas to life. Our focus is on user experience and modern design practices, ensuring that your website captures your brand identity, engages your audience, and drives results.
        </p>
        <p class="card-text">
          In addition to web development, we offer UX audits to help you optimize your website's user experience. Our comprehensive analysis covers usability, navigation, content structure, and overall user satisfaction. With actionable recommendations, we help you enhance user engagement and conversions, ensuring that your website delivers an exceptional experience.
        </p>
        <p class="card-text">
          Mobile Web Wizard is a new venture, driven by our passion for web development and dedication to providing outstanding services. Although we are just starting, our commitment to excellence and customer satisfaction remains unwavering. We aim to establish lasting connections with our clients and build a reputation for delivering high-quality results.
        </p>
        <p class="card-text">
          We invite you to explore our services and learn more about how Mobile Web Wizard can help you establish a strong online presence. Connect with us and experience the difference of working with a web development agency that goes the extra mile.
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
    name: 'AppAbout',
}
</script>

<style scoped>
/* Component-specific styles here */
</style>
